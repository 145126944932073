.base {
  display: flex;
  flex-direction: row;
  align-items: center;
  scroll-margin-top: var(--c-space-128);
  max-width: var(--c-text-block-max-width);
  margin-top: var(--v-space-64);

  + * {
    margin-top: var(--v-space-16) !important;
  }

  @media (--v-from-md) {
    margin-top: var(--c-space-96);

    + * {
      margin-top: var(--v-space-24) !important;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  width: var(--v-space-48);
  height: var(--v-space-48);
  margin-inline-start: var(--v-space-8);
  border-radius: 50%;
  transition: background 0.2s ease;

  & img {
    margin: 0 auto;
  }

  &:hover {
    background-color: var(--v-color-background-secondary);
  }
}

.marginReset {
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  & + div {
    margin-top: 0 !important;
  }
}
